import { Grid, Typography, Link } from '@mui/material';
import React from 'react';


export default function Code() {

    return (

        <Grid container xs={11} sm={8} md={7} lg={5} mb={4}>

          {/* Spacer */}
          <Grid item xs={12} sx={{height: 50}}/>

            {/* Pic */}
            <Grid item xs={12} mb={7} 
                style={{textAlign: "center"}}>
              <img src="Code.png" alt="code" style={{width: 250}}/>
            </Grid>

          {/* Description */}
          <Grid item xs={12} mb={5} order={3}>
              <Typography 
                  sx={{
                    fontFamily: "Inter",
                    fontSize: {xs:28, md:34}, fontWeight: 300}}>
                        Web applications for biosensor development
                    </Typography>
            </Grid>

            <Grid item xs={12} mt={1} order={3}>
              <Typography 
                  sx={{
                    fontFamily: "Inter",
                    fontSize: {xs:14, sm: 16, md:18}, display: "inline"}}>
                        Oftentimes web interfaces are needed to get bioengineers to actually use software.
                        I've created several web-based tools to help researchers build and improve
                        genetic biosensors for synthetic biology applications. You can find all of my 
                        public coding projects on
                        <a href="https://github.com/simonsnitz" target="__blank"> GitHub.</a>

                    </Typography>

            </Grid>

            <Grid item xs={12} mt={5} order={3}>
              <Typography 
                  sx={{
                    fontFamily: "Inter",
                    fontSize: {xs:24, md:28}, fontWeight: 300}}>
                        groovDB
                    </Typography>
            </Grid>

            <Grid item xs={12} sm={6} mt={1} order={3}>
              <Typography 
                  sx={{
                    fontFamily: "Inter",
                    fontSize: {xs:14, sm: 16, md:18} }}>
                        Prokaryotic transcription factors (pTFs) are widely used as biosensors
                        for chemical-inducible control of gene expression, but information on them
                        is scattered across countless peer-reviewed publications. groovDB is a
                        centralized database that makes searching for pTFs simple.<br/>
                        <a href="https://pubs.acs.org/doi/10.1021/acssynbio.2c00382" target="__blank">Published here</a>
                    </Typography>
            </Grid>

            <Grid item xs={12} sm={6} mt={1} order={3} style={{textAlign: "center", margin: "auto"}}>
              <Link href="https://groov.bio" target="__blank">
              <img src="groovDB.png" alt="groovDB icon" style={{width: 250}}/>
              </Link>
            </Grid>

            <Grid item xs={12} mt={5} order={3}>
              <Typography 
                  sx={{
                    fontFamily: "Inter",
                    fontSize: {xs:24, md:28}, fontWeight: 300}}>
                        Snowprint
                    </Typography>
            </Grid>

            <Grid item xs={12} sm={6} mt={1} order={3}>
              <Typography 
                  sx={{
                    fontFamily: "Inter",
                    fontSize: {xs:14, sm: 16, md:18}, }}>
                        What DNA sequence does a pTF bind to? 
                        This question is usually answered by doing experiments that take months, 
                        like EMSA or DNAse Footprinting. Snowprint is a tool that uses phylogenetic
                        information to infer pTF-DNA interactions solely from the pTF's sequence. <br/>
                        <a href="https://www.nature.com/articles/s42003-024-05849-8" target="__blank">Published here</a>
                    </Typography>
            </Grid>
    
            <Grid item xs={12} sm={6} mt={1} order={3} style={{textAlign: "center", margin: "auto"}}>
            <Link href="https://snowprint.groov.bio" target="__blank">              
              <img src="Snowprint.png" alt="snowprint icon" style={{width: 250}}/>
            </Link>
            </Grid>


            <Grid item xs={12} mt={5} order={3}>
              <Typography 
                  sx={{
                    fontFamily: "Inter",
                    fontSize: {xs:24, md:28}, fontWeight: 300}}>
                        Ligify
                    </Typography>
            </Grid>

            <Grid item xs={12} sm={6} mt={1} order={3}>
              <Typography 
                  sx={{
                    fontFamily: "Inter",
                    fontSize: {xs:14, sm: 16, md:18} }}>
                        How can I find a pTF that binds my target chemical?
                        Again, lengthy experiments are usually needed to answer this question. 
                        Ligify is a tool that extracts and processes information from 3 biological databases
                        to predict a pTF that responds to the input chemical. <br/>
                        <a href="https://www.biorxiv.org/content/10.1101/2024.02.20.581298v1" target="__blank">Published here</a>
                    </Typography>
            </Grid>

            <Grid item xs={12} sm={6} mt={1} order={3} style={{textAlign: "center", margin: "auto"}}>
            <Link href="https://ligify.streamlit.app" target="__blank">
              <img src="Ligify.png" alt="ligify icon" style={{width: 300}}/>
            </Link>
            </Grid>

        </Grid>

        

);
}