import Box from '@mui/material/Box';

import Home from './Components/Home.js';

import { Routes, Route } from 'react-router-dom';

import { useMediaQuery, useTheme } from '@mui/material';

function App() {

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box
    display="grid"
    gridTemplateColumns="repeat(12, 1fr)"
    gridTemplateRows={isSmallScreen ? `56px` : `64px`}
    gridAutoRows="auto"
    sx={{ height: '100%' }}
    >
    <Box gridColumn="span 12">

    <Routes>
      
          <Route path="/*" element={<Home />} />

    </Routes>

      {/* <Home/> */}

    </Box>
  </Box>

  );
}

export default App;
