
import { Grid } from '@mui/material';
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import NavBar from './NavBar.js';
import About from './About.js';
import Code from './Code.js';
// import Research from './Research.js';

export default function Home() {

    return (

      //Parent container
    <Grid
        container
        spacing={0}
        alignItems="center"
        width= '100vw'
        justifyContent="center"
      >

    <img src="icon.png" alt="icon" style={{width: 50}}/>
    <NavBar/>
    <Routes>
          <Route path="/" element={<About/>} />
          <Route path="about" element={<About/>} />
          <Route path="code" element={<Code/>} />
          {/* <Route path="research" element={<Research/>} /> */}
    </Routes>

    </Grid>

        

    );
}