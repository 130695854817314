import { Grid, Typography, Link } from '@mui/material';
import React from 'react';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';


export default function About() {

    return (

        <Grid container xs={11} sm={8} md={7} lg={5} mb={4}>

          {/* Spacer */}
          <Grid item xs={12} sx={{height: 50}}>
            </Grid> 

            {/* Profile Pic */}
            <Grid item xs={2} sm={3}/>
            <Grid item xs={8} sm={6} 
                textAlign="center">
              <img src="profile.jpg" alt="profile" style={{width: "100%"}}/>
            </Grid>
            <Grid item xs={2} sm={3}/>

          {/* My name */}
            <Grid item xs={12}>
              <Typography mt={3}
                  sx={{
                    fontFamily: "Inter",
                    textAlign: "center",
                    lineHeight: 1.2,
                    fontWeight: 300,
                    fontSize: {xs:30, sm:30, md:36, lg:44}}}>
                      Simon d'Oelsnitz
              </Typography>

            </Grid>


            <Grid item xs={12} mt={5} order={3}>
              <Typography 
                  sx={{
                    fontFamily: "Inter",
                    fontSize: {xs:14, sm: 16, md:18}}}>
                        I am a Synthetic Biology Fellow at Harvard Medical School working with sustainability-focused groups in the
                        <a href="https://synbiohive.org/" target="_blank" rel="noopener noreferrer"> Synthetic Biology Hive </a>
                        and the
                        <a href="https://wyss.harvard.edu/team/postdoctoral-fellow/simon-doelsnitz/" target="_blank" rel="noopener noreferrer"> Wyss Institute</a>
                        . From 2021 to 2023, I worked on large protein library measurements with the 
                        <a href="https://www.nist.gov/mml/bbd/cellular-engineering-group" target="_blank" rel="noopener noreferrer"> Cellular Engineering Group </a>
                        at the National Institute of Standards and Technology. 
                        Before that, I did my PhD at The University of Texas at Austin with Andy Ellington 
                        and Hal Alper where I used directed evolution to engineer 
                        <a href="https://pubs.acs.org/doi/abs/10.1021/acssynbio.1c00402" target="_blank" rel="noopener noreferrer"> transcription factor biosensors</a>
                        , 
                        <a href="https://www.nature.com/articles/s41589-022-01072-w" target="_blank" rel="noopener noreferrer"> enzymes</a>
                        , 
                        <a href="https://academic.oup.com/jimb/article/46/12/1715/5996814" target="_blank" rel="noopener noreferrer"> protein transporters</a>
                        , and 
                        <a href="https://onlinelibrary.wiley.com/doi/abs/10.1002/ange.202100579" target="_blank" rel="noopener noreferrer"> aminoacyl-tRNA synthetases</a>
                        . I have an undergraduate degree in Pharmacology from Stony Brook University.
                        <br/>
                        <br/>
                        <u>My expertise is in protein engineering and chemical biology.</u> <br/>
                        I'm particularly interested in using custom transcription factors as new measurement tools to better 
                        understand and engineer protein-chemical interfaces. To do this, I use a combination of directed evolution, 
                        barcode sequencing, and machine learning-aided protein design.
                        My past work has focused on using transcription factors to evolve plant alkaloid pathways 
                        (<a href="https://www.nature.com/articles/s41589-022-01072-w" target="_blank" rel="noopener noreferrer">BIAs</a>, 
                        <a href="https://www.nature.com/articles/s41467-024-46356-y" target="_blank" rel="noopener noreferrer"> AAs</a>).
                        My current work develops sensors for applications in sustainability, including biomanufacturing (make good chemicals)
                        and environmental remediation (detect + break down bad chemicals).
                    </Typography>
            
            <Grid item xs={12} mt={5}>

            <Typography 
                  sx={{
                    fontFamily: "Inter",
                    fontSize: {xs:14, sm: 16, md:18}}}>
              Contact
            </Typography>
              <Link
                href="https://www.linkedin.com/in/simon-doelsnitz/"
                target="_blank">
                <LinkedInIcon sx={{ width: '50px', height: '50px' }} />
              </Link>
              <Link
                href="https://twitter.com/simonsnitz"
                target="_blank">
                <TwitterIcon sx={{ width: '50px', height: '50px', ml:3 }} />
              </Link>

            </Grid>

          </Grid>

        </Grid>

        

);
}