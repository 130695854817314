import React from 'react';
import { Link } from 'react-router-dom';

export default function NavBar() {
  return (
    <nav style={{width: '100vw', height: 50,
        textAlign: 'center'
        }}>
    <hr/>
      <Link to="/about" style={{padding: 30, fontFamily: "Inter", fontSize: 16, textDecoration: 'none', color: "black"}}>ABOUT</Link>
      <Link to="/code" style={{padding: 30, fontFamily: "Inter", fontSize: 16, textDecoration: 'none', color: "black"}}>CODE</Link>
      <Link to="https://scholar.google.com/citations?hl=en&user=4lfhK5wAAAAJ" style={{padding: 30, fontFamily: "Inter", fontSize: 16, textDecoration: 'none', color: "black"}}>RESEARCH</Link>
      {/* <Link to="/research" style={{padding: 30, fontFamily: "Inter", fontSize: 16, textDecoration: 'none', color: "black"}}>RESEARCH</Link> */}
    <hr/>
    </nav>
    
  );
};
